import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CameraViewer from './CameraViewer';
import Header from './Header';

const HomeScreen = ({ model, modelLoaded, onNext, onPrev, onPlayPause }) => {
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();

  const bottomLeftItems = [
    { title: 'Who', content: 'me' },
    {
      title: 'What',
      content: [
        { name: 'Work', link: 'https://connorpower.net' },
        { name: 'Writing', link: 'https://connorpower.net' },
      ],
    },
    {
      title: 'Where',
      content: [
        { name: 'Globe', route: '/globe' },
      ],
    },
  ];

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        padding: '20px',
        backgroundColor: 'black',
        overflow: 'hidden',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      }}
    >
      <Header 
        onNext={onNext}
        onPrev={onPrev}
        onPlayPause={onPlayPause}
      />

      {/* Centered CameraViewer */}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <CameraViewer model={model} modelLoaded={modelLoaded} />
      </div>

      {/* Bottom left container */}
      <div
        style={{
          position: 'absolute',
          bottom: 160,
          left: 10,
          color: 'white',
          textAlign: 'left',
        }}
      >
        {bottomLeftItems.map((item, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <div
              onClick={() => setActiveItem(activeItem === index ? null : index)}
              style={{
                cursor: 'pointer',
                color: activeItem === index ? '#ADD8E6' : 'white',
                padding: '5px 10px',
                userSelect: 'none',
              }}
            >
              {item.title}
            </div>
            {activeItem === index && (
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '5px',
                  color: '#D3D3D3',
                }}
              >
                {Array.isArray(item.content) ? (
                  item.content.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      onClick={() => subItem.link 
                        ? window.open(subItem.link, '_blank', 'noopener,noreferrer')
                        : handleNavigation(subItem.route)
                      }
                      style={{
                        cursor: 'pointer',
                        color: '#ADD8E6',
                        padding: '5px 10px',
                        userSelect: 'none',
                      }}
                    >
                      {subItem.name}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      paddingLeft: '10px',
                      color: '#D3D3D3',
                      userSelect: 'none',
                    }}
                  >
                    {item.content}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeScreen;
