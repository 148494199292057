import React, { useRef, useEffect, useCallback } from 'react';
import * as THREE from 'three';

const CameraViewer = ({ model, modelLoaded }) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const mouseRef = useRef(new THREE.Vector2());

  const handleResize = useCallback(() => {
    if (cameraRef.current && rendererRef.current) {
      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    }
  }, []);

  const onMouseMove = useCallback((event) => {
    mouseRef.current.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouseRef.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }, []);

  useEffect(() => {
    if (!modelLoaded) return;

    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
    rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    rendererRef.current.setClearColor(0x000000, 1);
    mountRef.current.appendChild(rendererRef.current.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
    sceneRef.current.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(1, 1, 1);
    sceneRef.current.add(directionalLight);

    cameraRef.current.position.set(0, 0, 2);
    cameraRef.current.lookAt(0, 0, -2);

    sceneRef.current.add(model);

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('resize', handleResize);

    const animate = () => {
      requestAnimationFrame(animate);
      
      // Increase sensitivity by adjusting these multipliers
      const targetRotationY = mouseRef.current.x * Math.PI / 3; // Increased from PI / 4
      const targetRotationX = -mouseRef.current.y * Math.PI / 3; // Increased from PI / 4

      // Increase responsiveness by adjusting this factor
      const rotationSpeed = 0.2; // Increased from 0.1
      
      model.rotation.y += (targetRotationY - model.rotation.y) * rotationSpeed;
      model.rotation.x += (targetRotationX - model.rotation.x) * rotationSpeed;

      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', onMouseMove);
      
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      if (sceneRef.current) {
        sceneRef.current.clear();
      }
    };
  }, [model, modelLoaded, handleResize, onMouseMove]);

  if (!modelLoaded) {
    return <div>Loading model...</div>;
  }

  return <div ref={mountRef} style={{ width: '100%', height: '100vh' }} />;
};

export default CameraViewer;