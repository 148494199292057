import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import EnterScreen from './EnterScreen';
import HomeScreen from './HomeScreen';
import GlobeScreen from './GlobeScreen'; // Import GlobeScreen
import GridScreen from './GridScreen';
import PhotosScreen from './PhotoScreen';
import './App.css';

function App() {
  const [modelLoaded, setModelLoaded] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const modelRef = useRef(null);
  const audioRef = useRef(null);

  const songs = [
    '/music/tha.mp3',
    '/music/BACK_TO_ME.mp3',
    '/music/crime_pays.mp3'
  ];

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      './camera2.glb',
      (gltf) => {
        modelRef.current = gltf.scene;
        const scale = 0.85;
        modelRef.current.scale.set(scale, scale, scale);
        modelRef.current.position.set(0, 0, -2);
        setModelLoaded(true);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.error('An error happened', error);
      }
    );

    // Setup audio with first song
    audioRef.current = new Audio(songs[currentSongIndex]);
    audioRef.current.loop = true;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const handleAudioPlay = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.log('Audio playback failed:', error);
      });
    }
  };

  const handleNextSong = () => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    changeSong(nextIndex);
  };

  const handlePrevSong = () => {
    const prevIndex = (currentSongIndex - 1 + songs.length) % songs.length;
    changeSong(prevIndex);
  };

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const changeSong = (newIndex) => {
    const wasPlaying = !audioRef.current.paused;
    audioRef.current.pause();
    audioRef.current = new Audio(songs[newIndex]);
    audioRef.current.loop = true;
    setCurrentSongIndex(newIndex);
    if (wasPlaying) {
      audioRef.current.play();
    }
  };

  return (
    <Router>
      <div className="App" style={{ backgroundColor: 'black', height: '100vh' }}>
        <Routes>
          <Route path="/enter" element={<EnterScreen onEnter={handleAudioPlay} />} />
          <Route 
            path="/home" 
            element={
              <HomeScreen 
                model={modelRef.current} 
                modelLoaded={modelLoaded}
                onNext={handleNextSong}
                onPrev={handlePrevSong}
                onPlayPause={handlePlayPause}
              />
            } 
          />
          <Route path="/globe" element={<GlobeScreen />} />
          <Route path="/grid" element={<GridScreen />} />
          <Route path="/photos" element={<PhotosScreen />} />
          <Route path="*" element={<Navigate to="/enter" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
