import React, { useState, useRef, useEffect } from 'react';
import StyledContainer from './StyledContainer';

const Header = ({ onNext, onPrev, onPlayPause }) => {
  const [showAuxWindow, setShowAuxWindow] = useState(false);
  const [position, setPosition] = useState({ x: '50%', y: '50px' });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const auxWindowRef = useRef(null);

  // Move state management here
  const [currentDate, setCurrentDate] = useState('');
  const [growingNumber, setGrowingNumber] = useState(35767069768000);
  const [bitcoinPrice, setBitcoinPrice] = useState('Loading...');

  // Date and growing number effect
  useEffect(() => {
    // Update current date
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '.');
    setCurrentDate(formattedDate);

    // Update growing number every 100ms
    const intervalId = setInterval(() => {
      setGrowingNumber((prevNumber) => prevNumber + 16667);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  // Bitcoin price effect
  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice/USD.json');
        const data = await response.json();
        const price = Number(data.bpi.USD.rate.replace(/[^0-9.-]+/g, ''));
        setBitcoinPrice(`$${price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
      } catch (error) {
        console.error('Error fetching Bitcoin price:', error);
        setBitcoinPrice('Error');
      }
    };

    fetchBitcoinPrice();
    const intervalId = setInterval(fetchBitcoinPrice, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const topRightItems = [
    currentDate,
    { value: `$${growingNumber.toLocaleString()}`, link: 'https://www.usdebtclock.org/' },
    { value: bitcoinPrice, link: 'https://www.coindesk.com/price/bitcoin' },
  ];

  const handleMouseDown = (e) => {
    if (e.target.closest('.header__bg')) {
      setIsDragging(true);
      const rect = auxWindowRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      setPosition({
        x: `${newX}px`,
        y: `${newY}px`
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add event listeners when dragging starts
  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <>
      {/* AUX button */}
      <div
        style={{
          position: 'absolute',
          top: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
        }}
      >
        <button
          onClick={() => setShowAuxWindow(!showAuxWindow)}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid white',
            color: 'white',
            padding: '5px 15px',
            cursor: 'pointer',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            fontSize: '14px',
          }}
        >
          AUX
        </button>
      </div>

      {/* AUX window */}
      {showAuxWindow && (
        <StyledContainer
          ref={auxWindowRef}
          onMouseDown={handleMouseDown}
          style={{
            position: 'absolute',
            top: position.y,
            left: position.x,
            transform: position.x === '50%' ? 'translateX(-50%)' : 'none',
            zIndex: 1000,
            width: '400px',
            height: '150px',
            cursor: isDragging ? 'grabbing' : 'default',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          }}
        >
          <div 
            className="header__bg" 
            style={{ 
              cursor: 'grab',
              userSelect: 'none',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            }}
          >
            <div className="header__text">Auxiliary Controls</div>
          </div>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            gap: '15px',
            marginTop: '20px'
          }}>
            <button
              onClick={onPrev}
              style={{
                backgroundColor: '#ece9da',
                border: '1px solid #666',
                color: 'black',
                padding: '5px 15px',
                cursor: 'pointer',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '14px',
                width: '70px'
              }}
            >
              ←
            </button>
            <button
              onClick={onPlayPause}
              style={{
                backgroundColor: '#ece9da',
                border: '1px solid #666',
                color: 'black',
                padding: '5px 15px',
                cursor: 'pointer',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '14px',
                width: '70px'
              }}
            >
              ▶/❚❚
            </button>
            <button
              onClick={onNext}
              style={{
                backgroundColor: '#ece9da',
                border: '1px solid #666',
                color: 'black',
                padding: '5px 15px',
                cursor: 'pointer',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '14px',
                width: '70px'
              }}
            >
              →
            </button>
          </div>
        </StyledContainer>
      )}

      {/* Top right container */}
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          textAlign: 'right',
          color: 'white',
          zIndex: 1000,
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        }}
      >
        <ul style={{ listStyleType: 'none', padding: 10, marginRight: 40 }}>
          {topRightItems.map((item, index) => (
            <li
              key={index}
              style={{
                marginBottom: 5,
                cursor: item.link ? 'pointer' : 'default',
                userSelect: 'none',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              }}
              onClick={() =>
                item.link && window.open(item.link, '_blank', 'noopener,noreferrer')
              }
            >
              {item.value || item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Header; 