// src/components/GlobeScreen.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Globe from 'react-globe.gl';
import Header from './Header';

const GlobeScreen = () => {
  const navigate = useNavigate();
  const globeEl = useRef();
  const [pointsData, setPointsData] = useState([]);
  const [hoveredHex, setHoveredHex] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [preloadedImages, setPreloadedImages] = useState(new Set());
  const imageCache = useRef(new Map());

  useEffect(() => {
    // Fetch the photo data from photosData.json
    fetch('/photosData.json')
      .then((response) => response.json())
      .then((photoData) => {
        const validPoints = photoData.filter(
          (photo) =>
            typeof photo.lat === 'number' &&
            typeof photo.lng === 'number'
        );
        setPointsData(validPoints);
      })
      .catch((error) => console.error('Error fetching photos data:', error));
  }, []);

  // Preload image function
  const preloadImage = useCallback((fileName) => {
    return new Promise((resolve, reject) => {
      if (imageCache.current.has(fileName)) {
        resolve(imageCache.current.get(fileName));
        return;
      }

      const img = new Image();
      img.onload = () => {
        imageCache.current.set(fileName, img.src);
        resolve(img.src);
      };
      img.onerror = reject;
      img.src = `/photos/${fileName}`;
    });
  }, []);

  // Preload images for a hex
  const preloadHexImages = useCallback(async (hex) => {
    if (!hex?.points) return;
    
    const newImages = hex.points.filter(
      photo => !preloadedImages.has(photo.fileName)
    );

    if (newImages.length === 0) return;

    const preloadPromises = newImages.map(photo => 
      preloadImage(photo.fileName)
        .then(() => photo.fileName)
        .catch(err => console.warn(`Failed to preload ${photo.fileName}:`, err))
    );

    const loadedFiles = await Promise.allSettled(preloadPromises);
    const successfulLoads = loadedFiles
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value);

    setPreloadedImages(prev => new Set([...prev, ...successfulLoads]));
  }, [preloadImage, preloadedImages]);

  // When hex is hovered, preload its images
  useEffect(() => {
    if (hoveredHex) {
      preloadHexImages(hoveredHex);
    }
  }, [hoveredHex, preloadHexImages]);

  const ringsData = [
    {
      lat: 53.3498,
      lng: -6.2603,
      color: 'green',
    },
    {
      lat: 42.3601,
      lng: -71.0589,
    }
  ];

  const computeCenter = (points) => {
    if (!points || points.length === 0) return null;
    const total = points.reduce(
      (acc, point) => ({
        lat: acc.lat + point.lat,
        lng: acc.lng + point.lng,
      }),
      { lat: 0, lng: 0 }
    );
    return {
      lat: total.lat / points.length,
      lng: total.lng / points.length,
    };
  };

  const handleHexClick = async (hex, event) => {
    console.log('Clicked hex:', hex);
    let lat, lng;

    if (hex.center && Array.isArray(hex.center))
      [lat, lng] = hex.center;
    else if (hex.__hexCenter && Array.isArray(hex.__hexCenter))
      [lat, lng] = hex.__hexCenter;
    else if (hex.points && hex.points.length > 0) {
      const center = computeCenter(hex.points);
      if (center) {
        lat = center.lat;
        lng = center.lng;
      }
    }

    if (lat !== undefined && lng !== undefined) {
      console.log(`Opening modal with photos at lat=${lat}, lng=${lng}`);
      // Preload images before showing modal
      await preloadHexImages(hex);
      setModalData({
        lat,
        lng,
        points: hex.points,
      });
    } else {
      console.warn('Clicked hex is undefined or missing center:', hex);
    }
  };

  const handleHexHover = (hex, previousHex) => {
    setHoveredHex(hex);
  };

  const closeModal = () => {
    setModalData(null);
  };

  // Modal Image Component with loading state
  const ModalImage = React.memo(({ photo, index }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const imgSrc = imageCache.current.get(photo.fileName) || `/photos/${photo.fileName}`;

    return (
      <div key={index} style={{ textAlign: 'center' }}>
        <div style={{ 
          position: 'relative',
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto'
        }}>
          {!isLoaded && (
            <div style={{
              width: '100%',
              height: '400px',
              backgroundColor: '#2a2a2a',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              Loading...
            </div>
          )}
          <img
            src={imgSrc}
            alt={photo.details}
            loading="lazy"
            style={{
              width: '100%',
              maxWidth: '800px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              display: isLoaded ? 'block' : 'none'
            }}
            onLoad={() => setIsLoaded(true)}
          />
        </div>
        <p style={{ marginTop: '10px', fontSize: '16px' }}>{photo.details}</p>
      </div>
    );
  });

  return (
    <div style={{
      textAlign: 'center',
      backgroundColor: 'black',
      color: 'white',
      height: '100vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Header 
        onNext={() => {}}
        onPrev={() => {}}
        onPlayPause={() => {}}
      />

      <button
        onClick={() => navigate(-1)}
        style={{
          padding: '10px 20px',
          fontSize: '18px',
          margin: '20px',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          cursor: 'pointer',
          backgroundColor: 'transparent',
          color: 'white',
          border: '1px solid white',
        }}
      >
        Back
      </button>

      <div style={{ 
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
        <Globe
          ref={globeEl}
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
          backgroundColor="black"
          width={800}
          height={800}
          hexBinPointsData={pointsData}
          hexBinPointLat={(d) => d.lat}
          hexBinPointLng={(d) => d.lng}
          hexBinPointWeight={() => 1}
          hexBinResolution={3}
          hexMargin={0.005}
          hexAltitude={({ sumWeight }) => sumWeight * 0.015}
          hexTopColor={() => 'orange'}
          hexSideColor={() => 'rgba(255, 165, 0, 0.8)'}
          hexLabel={({ points, sumWeight, center, __hexCenter }) => {
            let lat, lng;
            if (center && Array.isArray(center)) {
              [lat, lng] = center;
            } else if (__hexCenter && Array.isArray(__hexCenter)) {
              [lat, lng] = __hexCenter;
            } else if (points && points.length > 0) {
              const computedCenter = computeCenter(points);
              if (computedCenter) {
                lat = computedCenter.lat;
                lng = computedCenter.lng;
              }
            } else {
              return '';
            }
            return `
              <div style="text-align: center;">
                Lat: ${lat.toFixed(2)}, Lng: ${lng.toFixed(2)}<br/>
                Photos: ${points.length}
              </div>
            `;
          }}
          onHexClick={handleHexClick}
          onHexHover={handleHexHover}
          ringsData={ringsData}
          ringLat={(d) => d.lat}
          ringLng={(d) => d.lng}
          ringColor={() => 'green'}
          ringMaxRadius={6}
          onGlobeReady={() =>
            globeEl.current.pointOfView(
              { lat: 0, lng: 0, altitude: 3 },
              0
            )
          }
        />
      </div>

      {hoveredHex && (
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '10px 15px',
          borderRadius: '5px',
          pointerEvents: 'none',
          zIndex: 2,
          maxWidth: '250px',
        }}>
          <p>
            <strong>Lat:</strong>{' '}
            {(() => {
              if (hoveredHex.center && hoveredHex.center.length === 2)
                return hoveredHex.center[0].toFixed(2);
              if (hoveredHex.__hexCenter && hoveredHex.__hexCenter.length === 2)
                return hoveredHex.__hexCenter[0].toFixed(2);
              if (hoveredHex.points && hoveredHex.points.length > 0) {
                const center = computeCenter(hoveredHex.points);
                if (center) return center.lat.toFixed(2);
              }
              return 'N/A';
            })()} <br />
            <strong>Lng:</strong>{' '}
            {(() => {
              if (hoveredHex.center && hoveredHex.center.length === 2)
                return hoveredHex.center[1].toFixed(2);
              if (hoveredHex.__hexCenter && hoveredHex.__hexCenter.length === 2)
                return hoveredHex.__hexCenter[1].toFixed(2);
              if (hoveredHex.points && hoveredHex.points.length > 0) {
                const center = computeCenter(hoveredHex.points);
                if (center) return center.lng.toFixed(2);
              }
              return 'N/A';
            })()} <br />
          </p>
        </div>
      )}

      {modalData && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 3,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              position: 'relative',
              width: '90%',
              maxWidth: '900px',
              maxHeight: '90%',
              backgroundColor: '#1e1e1e',
              padding: '20px',
              borderRadius: '8px',
              overflowY: 'auto',
              color: 'white',
              fontFamily: 'Arial, sans-serif',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                color: 'white',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
            {modalData.lat && modalData.lng && (
              <div style={{ marginBottom: '20px' }}>
                <h2>
                  {modalData.lat.toFixed(2)}, {modalData.lng.toFixed(2)}
                </h2>
              </div>
            )}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
              {modalData.points.map((photo, index) => (
                <ModalImage key={photo.fileName} photo={photo} index={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobeScreen;